import svg from '../img/error-my-ygeia-login.svg';

function ErrorMyYgeiaLogin() {
  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-xl rounded-3xl px-4 py-10 text-center"
      >
        <img src={svg} alt="login through my ygeia" className="mb-4 w-full" />
        <h1 className="mb-2 text-2xl font-bold md:text-3xl lg:text-4xl">
          Δεν είστε συνδεδεμένος
        </h1>
        <p className="mb-10">
          Η πρόσβαση στις υπηρεσίες κρατήσεων πραγματοποιείται αποκλειστικά μέσω
          της εφαρμογής My Ygeia.
        </p>
        <div className="w-full text-center">
          <a
            href="https://my-ygeia.gr/login"
            className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
          >
            <span>Μετάβαση στο My Ygeia</span>
            <svg
              fill="currentColor"
              className="ml-2.5 h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h4.59l-2.1 1.95a.75.75 0 001.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 10-1.02 1.1l2.1 1.95H6.75z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </main>
  );
}

export default ErrorMyYgeiaLogin;
